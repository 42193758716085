<div class="container-fluid">
  <div class="row mb-4">
    <button [cdkCopyToClipboard]="getEmails((teams$ | async))" (cdkCopyToClipboardCopied)="copied($event)" class="action-button form-button btn text-uppercase" type="button" name="copy" style="width: 300px !important;">
      <span>{{ 'event.copyEmails' | translate }}</span>
    </button>
  </div>
  <div class="row" *ngFor="let team of (teams$ | async) as teams">
    <div class="col-md-4 pt-2 event-top-border mb-4">
      <div class="item-header">
        <h5>{{ 'global.team' | translate }}</h5>
      </div>
      <div>
        <table class="table custom-table">
          <tbody>
          <tr>
            <td colspan="2">
              <img *ngIf="team.logo" src="{{team.logo}}" alt="" class="team-image">
              <img *ngIf="!team.logo" src="./assets/teams/team.png" alt="" class="team-image">
            </td>
          </tr>
          <tr>
            <td><strong>{{ 'team.name' | translate }}:</strong></td>
            <td>{{ team.name }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'global.playersCategory' | translate }}:</strong></td>
            <td>{{ 'global.playersCategoryItem.' + team.playersCategory | translate }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'global.address' | translate }}:</strong></td>
            <td>{{ team.location?.streetOne }}, {{ team.location?.streetTwo }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'global.postalCodeCity' | translate }}:</strong></td>
            <td>{{ team.location?.postalCode }} {{ team.location?.city }}</td>
          </tr>
          <tr>
            <td><strong>{{ 'teamAdmin.team.contacts' | translate }}:</strong></td>
            <td>
              <div *ngFor="let manager of team.managers">
                <div>{{manager.name}}</div>
                <div><a href="tel:{{manager.phoneNumber}}">{{manager.phoneNumber}}</a></div>
                <div><a href="mailto:{{manager.email}}">{{manager.email}}</a></div>
                <div></div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-4 event-top-border pt-2">
      <div ngbDropdown class="dropdown">
        <button ngbDropdownToggle class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ 'admin.news.options' | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="deactivateTeam(team.id)">{{ 'teamAdmin.team.delete' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
