import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService, Team, TeamPoints } from 'shared';
import { Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-admin-add-team-points',
  templateUrl: './admin-add-team-points.component.html',
  styleUrls: ['./admin-add-team-points.component.scss']
})
export class AdminAddTeamPointsComponent implements OnInit {
  public teamPointsForm = new FormGroup({
    teamId: new FormControl(undefined, [Validators.required]),
    points: new FormControl(undefined, [Validators.required])
  });
  public teams$: Observable<Team[]>;

  constructor(private adminService: AdminService, private modalService: ModalService, private toastService: ToastService) {
    this.teams$ = this.adminService.getAllTeams();
  }

  ngOnInit(): void {
  }

  public updateTeamPoints() {
    const request = this.teamPointsForm.value as TeamPoints;
    this.adminService.addTeamPoints(request).pipe(first(), catchError(err => this.modalService.showModal('modal.error', 'admin.teams.updatedContentError')))
      .subscribe(_ => {
        this.toastService.showToast('admin.teams.addedTitle', 'admin.teams.addedContent');
        this.teamPointsForm.reset();
      });
  }
}
