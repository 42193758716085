import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminService, GlobalAdminTeam, Team } from 'shared';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-admin-teams',
  templateUrl: './admin-teams.component.html',
  styleUrls: ['./admin-teams.component.scss']
})
export class AdminTeamsComponent implements OnInit {

  public teams$: Observable<GlobalAdminTeam[]>

  constructor(private adminService: AdminService, private modalService: ModalService, private toastService: ToastService) {
    this.teams$ = this.adminService.getAllTeams();
  }

  ngOnInit(): void {
  }

  public async deactivateTeam(teamId: number){
    await this.modalService.showConfirmation('teamAdmin.team.deleteTitle', 'teamAdmin.team.deleteContent')
      .subscribe(ok => {
        if (ok) {
          this.adminService.deactivateTeam(teamId).pipe(first()).subscribe(_ => {
              this.toastService.showToast('teamAdmin.team.deletedTitle', 'teamAdmin.team.deletedContent');
              this.teams$ = this.adminService.getAllTeams();
            }
            , error => {
              this.modalService.showModal('modal.error', 'admin.newsCategories.deletedContentError');
            });
        }
      });
  }

  public getEmails(teams: GlobalAdminTeam[]) {
    return teams.map(r => r.managers.map(m => m.email)).join(';');
  }

  public copied($event: any) {
    this.toastService.showToast('toast.copied', 'toast.emails');
  }
}
