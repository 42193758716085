<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div>
        <h1 class="header">{{ 'event.eventInfo' | translate }}</h1>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="sportEvent$ | async as sportEvent">
    <div class="col-md-8 pt-2 event-top-border">
      <table class="table custom-table">
        <tbody>
        <tr colspan="2">
          <td>
            <div class="item-header">
              <h5>{{ sportEvent.name }}</h5>
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>{{ 'event.startDate' | translate }}:</strong></td>
          <td>{{ sportEvent.startDate | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang() }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'event.endDate' | translate }}:</strong></td>
          <td>{{ sportEvent.endDate | date: customTranslationService.getDateFormat() : 'IST' : customTranslationService.getLang() }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'global.indoorSoccerEventTier' | translate }}:</strong></td>
          <td>{{ 'global.indoorSoccerEventTierItem.' + sportEvent.indoorSoccerEventTier | translate }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'global.indoorFootballEventType' | translate }}:</strong></td>
          <td>{{ 'global.indoorFootballEventTypeItem.' + sportEvent.indoorFootballEventType | translate }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'global.playersCategory' | translate }}:</strong></td>
          <td>{{ 'global.playersCategoryItem.' + sportEvent.playersCategory | translate }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'event.inviteOnly' | translate }}:</strong></td>
          <td>{{ (sportEvent.inviteOnlyEvent ? 'global.yes' : 'global.no') | translate }}</td>
        </tr>
        <tr>
          <td>
            <strong>{{ 'event.venue' | translate }}:</strong>
          </td>
          <td>{{ sportEvent.venue }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'global.address' | translate }}:</strong></td>
          <td>{{ sportEvent.location.streetOne }}, {{ sportEvent.location.streetTwo }}</td>
        </tr>
        <tr>
          <td><strong>{{ 'global.postalCodeCity' | translate }}:</strong></td>
          <td>{{ sportEvent.location.postalCode }} {{ sportEvent.location.city }}</td>
        </tr>
        </tbody>
      </table>
      <div class="item-header">
        <h5>{{ 'event.registeredTeamContacts' | translate }}</h5>
      </div>
      <div>
        <button [cdkCopyToClipboard]="getEmails(sportEvent.registeredTeams)" (cdkCopyToClipboardCopied)="copied($event)" class="action-button form-button btn text-uppercase" type="button" name="copy" style="width: 300px !important;">
          <span>{{ 'event.copyEmails' | translate }}</span>
        </button>e
      </div>
      <div class="event-top-border mt-2" *ngFor="let registeredTeam of sportEvent.registeredTeams">
        <div class="row">
          <div class="col-md-6">
            <table class="table custom-table">
              <tbody>
              <tr>
                <td><strong>{{ 'global.team' | translate }}</strong></td>
                <td>{{ registeredTeam.name }}</td>
              </tr>
              <tr>
                <td><strong>{{ 'event.contactName' | translate }}</strong></td>
                <td>{{ registeredTeam.contactName }}</td>
              </tr>
              <tr>
                <td><strong>{{ 'event.contactPhone' | translate }}</strong></td>
                <td><a href="tel:{{registeredTeam.contactPhone}}">{{ registeredTeam.contactPhone }}</a></td>
              </tr>
              <tr>
                <td><strong>{{ 'event.contactEmail' | translate }}</strong></td>
                <td><a href="mailto:{{registeredTeam.contactEmail}}">{{ registeredTeam.contactEmail }}</a></td>
              </tr>
              <tr>
                <td><strong>{{ 'global.timeOfDay' | translate }}</strong></td>
                <td>
                  <div *ngFor="let timeOfDay of registeredTeam.timeOfDay">
                    {{ 'global.timeOfDayItem.' + timeOfDay | translate }}
                  </div>
                </td>
              </tr>
              <tr>
                <td><strong>{{ 'event.Bemærkninger' | translate }}</strong></td>
                <td style=" word-break: break-all">{{ registeredTeam.comment }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6 align-self-center" *ngIf="utilService.dateAfterNow(sportEvent.startDate)">
            <div class="my-text-center">
              <button class="action-button form-button" (click)="removeTeam(registeredTeam)">
                {{ 'userEvent.removeTeam' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
