import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { Company } from '../interfaces/company';
import { ValidateCompany } from '../interfaces/validate-company';
import { apiUrlToken } from '../injection-tokens';
import { Team } from '../interfaces/team';
import { TeamPoints } from '../interfaces/team-points';
import { EventShopUser } from '../interfaces/eventShopUser';
import { GlobalAdminTeam } from '../interfaces/global-admin-team';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http: HttpClient, @Inject(apiUrlToken) private apiUrl: string) {
  }

  public getInvalidCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.apiUrl}/api/admin/GetInvalidCompanies`);
  }

  public getInvalidCompany(companyId: number): Observable<Company> {
    return this.http.get<Company>(`${this.apiUrl}/api/admin/GetInvalidCompany/${companyId}`);
  }

  public validateCompany(companyId: number, validated: boolean) {
    const request = {companyId, validated} as ValidateCompany;
    return this.http.post<ValidateCompany>(`${this.apiUrl}/api/Admin/ValidateCompany`, request);
  }

  public getAllTeams(): Observable<GlobalAdminTeam[]> {
    return this.http.get<GlobalAdminTeam[]>(`${this.apiUrl}/api/Admin/GetAllTeams`);
  }

  public addTeamPoints(teamPoints: TeamPoints): Observable<Team> {
    return this.http.post<Team>(`${this.apiUrl}/api/Admin/AddTeamPoints`, teamPoints);
  }

  public getAllUsers(): Observable<EventShopUser[]> {
    return this.http.get<EventShopUser[]>(`${this.apiUrl}/api/Admin/GetAllUsers/`);
  }

  public getUser(userId: string): Observable<EventShopUser> {
    return this.http.get<EventShopUser>(`${this.apiUrl}/api/Admin/GetUser/${userId}`);
  }

  public resetUserPassword(userId:string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/Admin/ResetUserPassword/${userId}`);
  }

  public deactivateTeam(id: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/Admin/DeactivateTeam/${id}`);
  }
}
